import { memo } from "react";

import { CardDownloadButton } from "@/components/Button/CardDownloadButton";
import CambioCard from "@/components/CambioCard";
import BarChart from "@/components/Charts/BarChart";
import LineChart from "@/components/Charts/LineChart";

import { ChartColors } from "@/js/constants/cambio";
import { convertToCsvAndDownload } from "@/js/utils/csvCreation";
import { processUnitValue, unitFormatter } from "@/js/utils/unitFormatter";

import { Unit57dEnum } from "@/Api/generated";
import { useAppContext } from "@/layouts/AppLayout/AppContext";

import { getSelectedYear, useDashboardContext, useDashboardResources } from "../utils";

export default memo(function WaterCard() {
  const { organizationName, featureConfigurations } = useAppContext();
  const { property, dateRange } = useDashboardContext();
  const { energyConsumptionModel, isLoading, hasErrored, hasInitiallyLoaded } =
    useDashboardResources(["property", "energyConsumption"], {
      useAnnualizedData: !!featureConfigurations.ORG_LEVEL_ANNUALIZED_DATA_ENABLED,
    });
  const waterData =
    featureConfigurations.ORG_LEVEL_ANNUALIZED_DATA_ENABLED ?
      energyConsumptionModel.get("annual_water_consumption") || []
    : energyConsumptionModel.get("monthly_water_consumption");
  const unit = unitFormatter(energyConsumptionModel.get("water_unit") || Unit57dEnum.GALLONS);

  return (
    <CambioCard
      title="Water"
      label={hasInitiallyLoaded ? unit : "\xa0"}
      hasErrored={hasErrored}
      isLoading={isLoading}
      actionBar={
        <CardDownloadButton
          name="Water"
          onClick={() => {
            convertToCsvAndDownload(
              [
                { title: "End Date", key: "date" },
                {
                  title: "Water",
                  key: "value",
                  processValue: (value) => {
                    return parseFloat(String(value)).toFixed(0);
                  },
                },
                { title: "Unit of Measurement", value: unit, processValue: processUnitValue },
              ],
              waterData,
              (property ? property.name : organizationName) + " Water",
            );
          }}
        />
      }
      className="WaterCard"
    >
      {hasInitiallyLoaded ?
        !featureConfigurations.ORG_LEVEL_ANNUALIZED_DATA_ENABLED ?
          <LineChart
            data={waterData}
            tooltipValueFormatter={(value) =>
              parseFloat(String(value)).toLocaleString("en-US", { maximumFractionDigits: 1 })
            }
            hasReferenceLine
            yAxisChartDataFields={[{ key: "value", color: ChartColors.TEAL_CAMBIO, name: "Usage" }]}
          />
        : <BarChart
            data={waterData}
            xAxisKey="date"
            hasReferenceLine
            hasLegend={false}
            yAxisChartDataFields={[
              {
                key: "value",
                color: ChartColors.TEAL_CAMBIO,
                style: (entry) =>
                  entry.date !== getSelectedYear(dateRange) ? { opacity: 0.3 } : {},
                name: "Usage",
              },
            ]}
          />

      : null}
    </CambioCard>
  );
});
