import SvgIcon from "@/components/SvgIcon";

import { classnames } from "@/js/utils/cambio";

interface DeltaProps {
  /** By default, we'll consider positive changes good or "green". But sometimes positive is bad! */
  inverted?: boolean;
  label?: string;
  /** Delta in percentage */
  value: number;
}

/**
 * Shows a percentage change with a caret icon in green (positive direction) or red (negative
 * direction).
 */
export default function Delta({ inverted, value, label }: DeltaProps) {
  const direction =
    value < 1 && value > -1 ? ""
    : value > 0 ? "positive"
    : "negative";

  return !window.isNaN(value) ?
      <span
        className={classnames("Delta", {
          // @ts-ignore
          [(direction === "positive") ^ inverted ? "positive" : "negative"]: !!direction,
        })}
      >
        {direction ?
          <SvgIcon name={direction === "positive" ? "caret-up" : "caret-down"} />
        : null}
        {direction ? `${Math.round(Math.abs(value))}%` : "No change"}
        {label ? `\xa0${label}` : null}
      </span>
    : null;
}
