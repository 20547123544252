import { animated, useSprings } from "@react-spring/web";
import sumBy from "lodash/sumBy";
import { useRef, useState } from "react";

import ChartTooltip from "@/components/Charts/ChartTooltip";

import { ChartColors } from "@/js/constants/cambio";
import { formatPercentage } from "@/js/utils/stringFormatter";

/** Tooltip positioning buffer */
const BUFFER = 20;

interface ProgressBarItem {
  /** Allow an item to override default color scheme */
  color?: string;
  display?: string;
  value: number;
}

interface MultiProgressBarProps {
  items: ProgressBarItem[];
  title?: string;
  /** Allow total to be explicitly passed in; if it isn't, assume all items total 100% */
  total?: number;
  withTooltip?: boolean;
}

/**
 * This is a progress bar with multiple entries. Entries can be passed in as absolute numbers and
 * they will be turned into percentages, taking up linear space along the progress bar. Each one
 * is a line the length of its percentage plus all previous, but that's just an implentation detail
 * in order to have a nice animation in. They are then reversed to make sure that they stack on top
 * of each other with native z-indices.
 *
 * Pass in a `total` value when the items might not comprise 100%; otherwise it is assumed they do.
 *
 * The tooltip uses the ChartTooltip, which is normally used within the recharts Tooltip component,
 * but since this is not that we just hand-roll the positioning.
 */
export default function MultiProgressBar({
  items = [],
  total,
  title = "",
  withTooltip,
}: MultiProgressBarProps) {
  /** Used for showing and placing the tooltip */
  const [mouseCoords, setMouseCoords] = useState<[number, number]>(null);
  const parentRef = useRef<HTMLDivElement>();

  const getItemColor = (index: number) => items[index].color || Object.values(ChartColors)[index];

  // if total is not passed in, assume all entries total 100%
  const inferredTotal = total ?? sumBy(items, "value");
  // absolute values are passed in; these are their percentages and what will be shown in a tooltip
  const percentages = items.map(({ value }) =>
    inferredTotal !== 0 ? (value * 100) / inferredTotal : 0,
  );
  // each span that corresponds to an item value is the width of all its previous percentages
  // plus its own percentage.
  const translationPercentages = percentages.map((num, i) => num + sumBy(percentages.slice(0, i)));
  const [springs] = useSprings(
    items.length,
    (i) => ({
      from: { width: "0%", backgroundColor: getItemColor(i) },
      to: {
        width: `${translationPercentages[i]}%`,
        backgroundColor: getItemColor(i),
      },
    }),
    [JSON.stringify(percentages)],
  );

  return (
    <div
      ref={parentRef}
      className="MultiProgressBar"
      {...(withTooltip ?
        {
          onMouseMove: (evt) => setMouseCoords([evt.clientX, evt.clientY]),
          onMouseLeave: (evt) => setMouseCoords(null),
        }
      : {})}
    >
      {/** These are reversed so that we can take advantage of natural z-index order */}
      {springs.map((props, i) => <animated.span key={i} style={props} />).reverse()}
      {mouseCoords ?
        <div
          className="tooltip-hovercard"
          style={{
            left: mouseCoords[0] - parentRef.current?.getBoundingClientRect().left + BUFFER,
            top: mouseCoords[1] - parentRef.current?.getBoundingClientRect().top,
          }}
        >
          <ChartTooltip
            data={{
              title,
              total: formatPercentage(translationPercentages.at(-1)),
              breakdown:
                items.length > 1 ?
                  items.map((item, i) => ({
                    color: getItemColor(i),
                    name: item.display,
                    value: formatPercentage(percentages[i]),
                  }))
                : [],
            }}
            labelKey="title"
            chartDataFields={[
              {
                key: "total",
                ...(items.length === 1 ?
                  { color: getItemColor(0), name: "Total" }
                : { color: null, name: null, iconShape: null }),
              },
            ]}
          />
        </div>
      : null}
    </div>
  );
}
