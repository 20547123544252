import type { Filter, FilterTypes } from "@/components/Filters";
import type { FeatureFlags } from "@/js/models/types";

import dayjs, { Dayjs } from "dayjs";
import pick from "lodash/pick";
import { useContext } from "react";
import { ExecutorFunction, useResources } from "resourcerer";

import useRouter from "@/js/hooks/useRouter";

import { useAppContext } from "@/layouts/AppLayout/AppContext";

import { DashboardContext } from ".";

/**
 * A few property-related (individual or aggregate) resources requested by many different
 * components. This is a way to better ensure that each component requests the same resource and
 * we don't accidentally send out multiple requests because one forgot, say, the "include_ranking"
 * prop.
 */
export type PropertyResources =
  | "property"
  | "campus"
  | "totalFootprint"
  | "energySource"
  | "energyConsumption"
  | "meterBreakdown"
  | "weatherData"
  | "utilitySpending"
  | "scopeBreakdown"
  | "onboardingSummary"
  | "emissionReduction"
  | "surveyScore"
  | "climateRisks"
  | "aggregateCertifications"
  | "certifications";

type PropertyResourceProps = {
  space_token: string;
  organization_token: string;
  organization_id: string;
  spaceId?: number;
  start_date: string;
  filters: Record<FilterTypes, string>;
  include_benchmark?: boolean;
  include_ranking?: boolean;
  end_date: string;
  energySourceGridOnly?: boolean;
  ptnzLocked?: boolean;
  certificationAutomationEnabled?: boolean;
  surveyScoreEnabled?: boolean;
  climateRiskEnabled?: boolean;
  aggregateCertificationsEnabled?: boolean;
  weatherDataNonCritical?: boolean;
};

export const getResources: ExecutorFunction<PropertyResources, PropertyResourceProps> = ({
  space_token,
  ...props
}) => ({
  ...(space_token ?
    {
      property: {
        ...(isCampus(space_token) ?
          {
            path: {
              orgToken: props.organization_token,
              campusToken: space_token,
            },
            resourceKey: "campus",
          }
        : {
            params: {
              organization_token: props.organization_token,
              spaces: space_token,
              ...(props.include_ranking ? { include_ranking: true } : {}),
            },
            resourceKey: "property",
          }),
      },
    }
  : {}),
  totalFootprint: {
    params: {
      organization_id: props.organization_id,
      start_date: props.start_date,
      end_date: props.end_date,
      ...props.filters,
      ...getSpaceQueryParam(space_token, props.spaceId),
    },
    ...(space_token && !isCampus(space_token) ? { dependsOn: !!props.spaceId } : {}),
  },
  energySource: {
    params: {
      organization_id: props.organization_id,
      start_date: props.start_date,
      end_date: props.end_date,
      ...props.filters,
      ...(props.include_benchmark ? { include_benchmark: true } : {}),
      ...getSpaceQueryParam(space_token, props.spaceId),
      ...(props.energySourceGridOnly ? { grid_only: true } : {}),
    },
    ...(space_token && !isCampus(space_token) ? { dependsOn: !!props.spaceId } : {}),
  },
  energyConsumption: {
    params: {
      organization_id: props.organization_id,
      start_date: props.start_date,
      end_date: props.end_date,
      ...props.filters,
      ...(props.include_benchmark ? { include_benchmark: true } : {}),
      ...getSpaceQueryParam(space_token, props.spaceId),
    },
    ...(space_token && !isCampus(space_token) ? { dependsOn: !!props.spaceId } : {}),
  },
  meterBreakdown: {
    params: {
      organization_token: props.organization_token,
      start_date: props.start_date,
      end_date: props.end_date,
      ...props.filters,
      ...(space_token ?
        isCampus(space_token || "") ? { campus_token: space_token }
        : { space_tokens: space_token }
      : {}),
    },
  },

  utilitySpending: {
    params: {
      organization_id: props.organization_id,
      start_date: props.start_date,
      end_date: props.end_date,
      ...props.filters,
      ...getSpaceQueryParam(space_token, props.spaceId),
    },
    ...(space_token && !isCampus(space_token) ? { dependsOn: !!props.spaceId } : {}),
  },

  scopeBreakdown: {
    params: {
      start_date: props.start_date,
      end_date: props.end_date,
      ...props.filters,
      ...(space_token ?
        { [isCampus(space_token) ? "campus_token" : "space_token"]: space_token }
      : {}),
    },
    path: { organization_token: props.organization_token },
  },

  onboardingSummary: {
    params: {
      organization_id: props.organization_id,
      ...getSpaceQueryParam(space_token, props.spaceId, "space_id"),
      ...props.filters,
    },
    ...(space_token && !isCampus(space_token) ? { dependsOn: !!props.spaceId } : {}),
  },

  emissionReduction: {
    params: props.filters,
    dependsOn: !props.ptnzLocked,
    path: { orgToken: props.organization_token },
  },

  surveyScore: {
    params: {
      year: getSelectedYear([props.start_date, props.end_date]),
      ...props.filters,
      ...(space_token ? { property_token: space_token } : {}),
    },
    path: { orgToken: props.organization_token },
    dependsOn: !!props.surveyScoreEnabled,
  },

  climateRisks: {
    path: {
      orgToken: props.organization_token,
      propertyToken: space_token,
    },
    dependsOn: !!props.climateRiskEnabled,
  },

  aggregateCertifications: {
    params: { organization_token: props.organization_token, ...props.filters },
    dependsOn: !!props.aggregateCertificationsEnabled,
  },

  ...(space_token ?
    {
      certifications: {
        path: {
          subPortfolioToken: props.organization_token,
          propertyToken: space_token,
        },
        dependsOn: !!props.certificationAutomationEnabled,
      },
    }
  : {}),

  ...(space_token && !isCampus(space_token) ?
    {
      weatherData: {
        noncritical: props.weatherDataNonCritical,
        params: {
          space_token: space_token,
          start_date: props.start_date,
          end_date: props.end_date,
        },
      },
    }
  : {}),
});

/**
 * Use this hook in your property components with a list of which resources you want, as well as
 * a couple props.
 *
 * const {propertyModel, energySourceModel} = useDashboardContentResources(
 *  ["property", "energySource"],
 *  {...}
 * );
 */
export const useDashboardResources = (
  resources: PropertyResources[],
  props: {
    dateRange?: [string, string];
    energySourceGridOnly?: boolean;
    spaceId?: number;
    space_token?: string;
    useAnnualizedData?: boolean;
    filters?: Filter[];
    organizationId?: string;
    featureConfigurations?: FeatureFlags;
    orgToken?: string;
    weatherDataNonCritical?: boolean;
  } = { dateRange: [] as any, featureConfigurations: null, weatherDataNonCritical: true },
) => {
  const { space_token } = { ...(useRouter().query as { space_token: string }), ...props }; // allow space_token to be overridden via passed props
  const { organizationId, organizationToken, featureConfigurations, isInPortfolioContext } = {
    ...useAppContext(),
    ...(props.featureConfigurations ? { featureConfigurations: props.featureConfigurations } : {}), // allow featureConfigurations to be overridden via passed props for screenshots
    ...(props.orgToken ? { organizationToken: props.orgToken } : {}),
  };
  const execFunction: ExecutorFunction<PropertyResources, PropertyResourceProps> = (_props) =>
    pick(getResources(_props), ...resources);
  // allow dashboard context to be overridden via passed props
  const { spaceId, dateRange, filters } = { ...useDashboardContext(), ...props };

  return useResources(execFunction, {
    organization_token: organizationToken,
    organization_id: props.organizationId ? props.organizationId : organizationId, // allow organizationId to be overridden via passed props
    space_token,
    spaceId,
    filters: getFiltersQueryParams(filters),
    ...getDateRangeQueryParams(dateRange, featureConfigurations, !!props.useAnnualizedData),
    include_ranking: !!featureConfigurations.ACTII_ENABLED,
    include_benchmark: true,
    energySourceGridOnly: props.energySourceGridOnly,
    ptnzLocked:
      isInPortfolioContext ||
      !featureConfigurations.PATH_TO_NET_ZERO_ORG_LEVEL_ENABLED ||
      !!space_token,
    certificationAutomationEnabled: featureConfigurations.CERTIFICATION_AUTOMATION_ENABLED,
    surveyScoreEnabled: featureConfigurations.CAMBIO_SURVEY_ENABLED,
    climateRiskEnabled: featureConfigurations.CLIMATE_RISK_ENABLED,
    aggregateCertificationsEnabled:
      featureConfigurations.ORG_LEVEL_AGGREGATE_CERTIFICATIONS_ENABLED,
    weatherDataNonCritical: props.weatherDataNonCritical,
  });
};

export const isCampus = (spaceToken: string = "") => !!spaceToken.startsWith("cmp_");

export function getSpaceQueryParam(
  spaceToken: string,
  spaceId: number,
  spaceQueryParam = "spaces",
) {
  if (!spaceToken) {
    return {};
  }

  // these default dashes are a hack so that the cache key isn't the same as when we
  // have no spaces at all, which would return a cached aggregate view.
  return isCampus(spaceToken) ?
      { campus_token: spaceToken }
    : { [spaceQueryParam]: spaceId || "-" };
}

/**
 * In most cases, we just want the dateRange props formatted as the API expects. But when the
 * customer prefers to see annualized views, we actually want to query their historical data (which
 * is the defaultDateRange, when that is set, which it should be for all annual-view customers).
 * So in that case, the dates we want to send over are the "all historical range." But this is also
 * not every time. For example, ValueSpotlight components still should only show a single year at
 * a time. It's only time series charts shown annually that we want to show the historical data
 * with the selected year highlighted.
 */
export function getDateRangeQueryParams(
  dateRange: [string, string],
  featureFlags: FeatureFlags,
  useAnnualizedData: boolean,
) {
  const dates = useAnnualizedData ? getHistoricalDateRange(featureFlags) : dateRange;

  // these are the start/end date query params and value format that the API expects
  return {
    start_date: dayjs(dates[0]).format("YYYY-MM-DD"),
    end_date: dayjs(dates[1]).format("YYYY-MM-DD"),
  };
}

/**
 * Takes a list of filters and returns an object where the key is the filter key and the value is
 * a comma-separated list of filter values. These can then be passed to useResources as a `param`
 * value for API queries.
 */
export function getFiltersQueryParams(filters: Filter[] = []): Record<FilterTypes, string> {
  return filters.reduce(
    (memo, { key, values = [] }) =>
      Object.assign(memo, values.length ? { [key]: values.join(",") } : {}),
    {} as Record<FilterTypes, string>,
  );
}

/**
 * When we display annual data, we're still querying with a date range from the beginning until
 * the end of that year. But we need to display a simple four-digit year, and for that, we pick
 * from the start date.
 */
export function getSelectedYear(dateRange: [string, string]) {
  return "" + dayjs(dateRange[0]).get("year");
}

/**
 * Helper function to take the earliest time of a group of dayjs objects.
 */
export function minDay(...days: Dayjs[]): Dayjs {
  return dayjs(Math.min(...days.map((day) => day.valueOf())));
}

export function useDashboardContext() {
  return useContext(DashboardContext);
}

/**
 * Allows us to specify via feature flags the all-historical window of a customer, defaulting to
 * last five years. In the (hopefully near) future, this will be provided via endpoint.
 */
export function getHistoricalDateRange(featureFlags: FeatureFlags) {
  return [
    featureFlags.ORG_LEVEL_HISTORICAL_DATA_WINDOW_STARTS_AT ?
      dayjs(featureFlags.ORG_LEVEL_HISTORICAL_DATA_WINDOW_STARTS_AT).toISOString()
    : dayjs().startOf("year").subtract(4, "years").toISOString(),
    featureFlags.ORG_LEVEL_HISTORICAL_DATA_WINDOW_ENDS_AT ?
      dayjs(featureFlags.ORG_LEVEL_HISTORICAL_DATA_WINDOW_ENDS_AT).toISOString()
    : dayjs().startOf("day").toISOString(),
  ];
}

/**
 * Messy logic to go through all of our options to get the date range we should use for our metrics
 * requests. This includes:
 *
 * * Use query params when we have them and they give a valid date range
 * * Use the default metrics end date for the "start" reference when dates are annualized
 * * Otherwise we use the default start/end dates, rounded to the start/end of the granularity
 *
 * Returns as an ISO string tuple.
 */
export function getMetricDateRange(
  query: Record<string, any>,
  defaultMetricsDateRange: { end: string; start: string },
  {
    featureConfigurations,
    granularity,
  }: { featureConfigurations?: FeatureFlags; granularity?: "month" | "day" | "year" } = {},
): [string, string] {
  granularity =
    featureConfigurations?.ORG_LEVEL_ANNUALIZED_DATA_ENABLED ? "year" : granularity || "month";

  const startDate =
    query.start_date && dayjs(decodeURIComponent(query.start_date)).isValid() ?
      decodeURIComponent(query.start_date)
      // for annualized orgs, make sure our default range encompasses an entire year. Use the end.
    : granularity === "year" ? defaultMetricsDateRange.end
    : defaultMetricsDateRange.start;

  const endDate =
    query.end_date && dayjs(decodeURIComponent(query.end_date)).isValid() ?
      decodeURIComponent(query.end_date)
    : defaultMetricsDateRange.end;

  return [
    dayjs(startDate).startOf(granularity).toISOString(),
    dayjs(endDate).endOf(granularity).toISOString(),
  ] as [string, string];
}
