import { CardDownloadButton } from "@/components/Button/CardDownloadButton";
import CambioCard from "@/components/CambioCard";
import BarChart from "@/components/Charts/BarChart";
import { type Filter } from "@/components/Filters";

import { ChartColors } from "@/js/constants/cambio";
import { calculateTrendLineData } from "@/js/utils/calculateTrendLineData";

import { useAppContext } from "@/layouts/AppLayout/AppContext";
import {
  getSelectedYear,
  useDashboardResources,
} from "@/pages/DashboardPage/DashboardContent/utils";

import { type BenchmarksDrillDownInfoOption } from "..";

const ChartConfig: Record<
  BenchmarksDrillDownInfoOption,
  { description: string; label: string; key: string; name: string }
> = {
  energy_usage_intensity: {
    description: "Energy usage intensity",
    label: "kBtu psf",
    key: "value",
    name: "Energy Usage Intensity",
  },
  carbon_emissions: {
    label: "metric tons CO2e",
    description: "Carbon emissions",
    key: "carbon",
    name: "CO2e",
  },
  carbon_emission_intensity: {
    label: "metric tons CO2e psf",
    description: "Carbon emissions intensity",
    key: "carbon_emission_intensity.value",
    name: "CO2e intensity",
  },
};

interface TimeSeriesCardProps {
  dateRange: [string, string];
  filters?: Filter[];
  spaceId: number;
  downloadAction: (data: Record<string, any>[]) => void;
  type: BenchmarksDrillDownInfoOption;
}

/**
 * Shows a monthly or annual time series for a given benchmark metric. For monthly data, we can
 * use the same resources that we use for benchmarks, and we should largely have them already
 * loaded by the time this is rendered. For annualized data, though, we need to request over all
 * historical data, which are additional resources to what we use for benchmarks, which all rely on
 * the current single year data range. We should already have requested these historical data
 * resources (though they may not have loaded) from the charts in the dashboard (but not the
 * benchmarks).
 */
export default function TimeSeriesCard({
  downloadAction,
  dateRange,
  filters = [],
  spaceId,
  type,
}: TimeSeriesCardProps) {
  const { featureConfigurations } = useAppContext();
  const useAnnualizedData = featureConfigurations.ORG_LEVEL_ANNUALIZED_DATA_ENABLED;

  const { energySourceModel, energyConsumptionModel, isLoading } = useDashboardResources(
    type === "energy_usage_intensity" ? ["energyConsumption"] : ["energySource"],
    { dateRange, useAnnualizedData, filters, spaceId },
  );

  const data = (() => {
    switch (type) {
      case "energy_usage_intensity":
        return useAnnualizedData ?
            energyConsumptionModel.get("annual_eui")
          : energyConsumptionModel.get("monthly_eui");
      case "carbon_emissions":
        return useAnnualizedData ?
            energySourceModel.get("annual_carbon_emission")
          : energySourceModel.get("monthly_carbon_emission");
      case "carbon_emission_intensity":
        return useAnnualizedData ?
            energySourceModel.get("annual_carbon_emission_intensity")
          : energySourceModel.get("monthly_carbon_emission_intensity");
      default:
        [];
    }
  })();

  const changePercentage = internalPercent(data, ChartConfig[type].key);

  return (
    <CambioCard
      title="Time Series"
      icon="clock-countdown"
      className="TimeSeriesCard"
      label={ChartConfig[type].label}
      actionBar={<CardDownloadButton onClick={() => downloadAction(data)} />}
      isLoading={isLoading}
      subtitle={
        <>
          {ChartConfig[type].description} is{" "}
          {changePercentage < 0 ?
            <>
              trending <span className="green">downward</span>
            </>
          : changePercentage > 0 ?
            <>
              trending <span className="red">upward</span>
            </>
          : "not trending upward or downward"}{" "}
          over this period
        </>
      }
    >
      <BarChart
        data={data}
        xAxisKey="date"
        yAxisChartDataFields={[
          {
            key: ChartConfig[type]?.key,
            name: ChartConfig[type]?.name,
            color: ChartColors.TEAL_CAMBIO,
            style: (entry) =>
              useAnnualizedData && entry.date !== getSelectedYear(dateRange) ?
                { opacity: 0.3 }
              : {},
          },
        ]}
        tooltipValueFormatter={(value) =>
          (value || 0).toLocaleString("en-US", { maximumFractionDigits: 1 })
        }
        height={300}
        hasLegend={false}
      />
    </CambioCard>
  );
}

function internalPercent(data: any, key: string) {
  if (!data) {
    return 0;
  }

  const trendLine = calculateTrendLineData(data, "date", key);

  return calculatePercentChange(trendLine);
}

function calculatePercentChange(trendLine: any) {
  if (trendLine.length < 2) {
    return 0;
  }

  const firstY = trendLine[0].y;
  const lastY = trendLine[trendLine.length - 1].y;

  const percentChange = ((lastY - firstY) / firstY) * 100;

  return Math.round(percentChange);
}
