import SidePanel from "@/components/SidePanel";

import useSidePanelContext from "@/js/hooks/useSidePanelContext";
import { withUnits } from "@/js/utils/stringFormatter";

import { Campus, Space } from "@/Api/generated";

import PropertyLink from "../PropertyLink";

interface AssociatedPropertiesProps {
  properties: (Campus | Space)[];
}

export default function AssociatedPropertiesPanel({ properties }: AssociatedPropertiesProps) {
  const { closePanel } = useSidePanelContext();

  return (
    <SidePanel
      className="AssociatedPropertiesPanel"
      title="Associated Properties"
      header={<span className="subtext">{withUnits(properties.length, "property")}</span>}
    >
      <ul>
        {properties.map((property, i) => (
          <PropertyLink key={i} onClick={closePanel} {...property} />
        ))}
      </ul>
    </SidePanel>
  );
}
