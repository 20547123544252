import type { IconsType } from "@/components/SvgIcon";

import { ReactNode } from "react";

import SvgIcon from "@/components/SvgIcon";

import { classnames } from "@/js/utils/cambio";

export interface Button {
  key: any;
  disabled?: boolean;
  icon?: IconsType;
  invalid?: boolean;
  text: ReactNode;
  /** this onClick will override the component-level onClick */
  onClick?: () => void;
}

export interface ButtonGroupProps {
  items: Button[];
  /**
   * The isSelected denotes whether the currently selected value is already selected, so any client
   * component may choose to deselect it.
   */
  onClick?: (val: any, isSelected: boolean) => void;
  flavor?: "navigation" | "input";
  /** This can be an array if multiselect */
  selected: any | any[];
}

/**
 * This component is a simple array of buttons, but can take a couple different forms:
 *
 * * navigational - this is the default, where they are used to select one in a group of tabs that
 *   govern a view
 *
 * * input - where one or multiple may be selected and used as a data input. They can display a
 *   failed validation state.
 */
export default function ButtonGroup({
  items = [],
  flavor = "navigation",
  onClick,
  selected,
}: ButtonGroupProps) {
  const isItemSelected = (key: any) =>
    Array.isArray(selected) ? selected.includes(key) : selected === key;

  return (
    <div className={classnames("ButtonGroup", { [flavor]: flavor !== "navigation" })}>
      {items.map(({ icon, text, disabled, key, invalid, ...rest }) => (
        <button
          key={key}
          className={classnames({ selected: isItemSelected(key), invalid })}
          disabled={disabled}
          onClick={
            !disabled ?
              () => (rest.onClick ? rest.onClick() : onClick?.(key, isItemSelected(key)))
            : null
          }
        >
          {icon ?
            <SvgIcon name={icon} />
          : null}
          {text}
        </button>
      ))}
    </div>
  );
}
