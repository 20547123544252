import Button from "@/components/Button";
import CambioCard from "@/components/CambioCard";
import SidePanel from "@/components/SidePanel";

import useSidePanelContext from "@/js/hooks/useSidePanelContext";

import { certificationImages } from "../CertificationsCard";
import { CertificationsEditDrawer } from "../CertificationsEditDrawer";

export const CertificationsAddDrawer = ({
  hasLEED,
  hasEnergyStar,
  propertyName,
}: {
  hasLEED: boolean;
  hasEnergyStar: boolean;
  propertyName: string;
}) => {
  const { nextPanel } = useSidePanelContext();

  return (
    <SidePanel className="CertificationsAddDrawer" size="medium" title="Add Certification">
      <ul>
        <li
          onClick={() => {
            nextPanel(
              <CertificationsEditDrawer
                certification={{
                  certification_id: "",
                  certification_type: "LEED",
                  status: "",
                  last_certification_date: "",
                  certification_expiration_date: "",
                  score: "",
                }}
                propertyName={propertyName}
              />,
            );
          }}
        >
          <CambioCard>
            <div>
              <img src={certificationImages["LEED"]} alt="LEED Certification" />
              <div>
                <div>
                  <h5>LEED certification</h5>
                  {hasLEED ?
                    <span>This certification has already been added</span>
                  : null}
                </div>
              </div>
            </div>

            {hasLEED ?
              <Button flavor="link">Edit</Button>
            : null}
          </CambioCard>
        </li>
        <li
          onClick={() => {
            nextPanel(
              <CertificationsEditDrawer
                certification={{
                  certification_id: "",
                  certification_type: "EnergyStar",
                  status: "",
                  last_certification_date: "",
                  certification_expiration_date: "",
                  score: "",
                }}
                propertyName={propertyName}
              />,
            );
          }}
        >
          <CambioCard>
            <div>
              <img src={certificationImages["EnergyStar"]} alt="EnergyStar Certification" />
              <div>
                <h5>EnergyStar certification</h5>
                {hasEnergyStar ?
                  <span>This certification has already been added</span>
                : null}
              </div>
            </div>
            {hasEnergyStar ?
              <Button flavor="link">Edit</Button>
            : null}
          </CambioCard>
        </li>
      </ul>
    </SidePanel>
  );
};
