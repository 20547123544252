import dayjs from "dayjs";

export function doDatesSpanAtLeastXMonths(
  startDateStr: string,
  endDateStr: string,
  minMonthDifference: number,
): boolean {
  // Parse the date strings using dayjs
  const startDate = dayjs(startDateStr);
  const endDate = dayjs(endDateStr);

  // Calculate the difference in months
  const monthDifference = endDate.diff(startDate, "month");

  // Check if the difference is at least the specified number of months. The
  // monthly span will be the number of months in the range, which will include
  // the current month, so add 1 to the difference
  return monthDifference + 1 >= minMonthDifference;
}
