import type { ExecutorFunction } from "resourcerer";

import sortBy from "lodash/sortBy";
import { useResources } from "resourcerer";

import Button from "@/components/Button";
import SvgIcon from "@/components/SvgIcon";

import useModalContext from "@/js/hooks/useModalContext";
import useRouter from "@/js/hooks/useRouter";
import useSidePanelContext from "@/js/hooks/useSidePanelContext";
import { withUnits } from "@/js/utils/stringFormatter";

import { Campus, Space } from "@/Api/generated";
import { useAppContext } from "@/layouts/AppLayout/AppContext";

import AddPropertyToCampusModal from "../AddPropertyToCampusModal";
import AssociatedPropertiesPanel from "../AssociatedPropertiesPanel";
import PropertyLink from "../PropertyLink";
import { isCampus, useDashboardContext } from "../utils";

const getResources: ExecutorFunction<"campus", { campusToken: string; orgToken: string }> = (
  props,
) => ({
  campus: { path: props, dependsOn: !!props.campusToken },
});

export default function AssociatedPropertiesSection() {
  const { organizationToken } = useAppContext();
  const { space_token } = useRouter().query;
  const { property } = useDashboardContext();
  const { launch } = useModalContext();
  const { openPanel } = useSidePanelContext();
  const campusToken =
    isCampus(space_token as string) ? (space_token as string)
    : property && "campus" in property ? property.campus.token
    : null;
  // campusModel will just be the property if on the campus page
  const { campusModel, hasLoaded, hasErrored, isLoading } = useResources(getResources, {
    orgToken: organizationToken,
    campusToken,
  });

  const isCampusView = isCampus(space_token as string);
  // campus view always goes first. prepend it if not on a campus detail page
  const properties = [
    ...(isCampusView ? [] : [campusModel.toJSON()]),
    ...sortBy(campusModel.get("spaces") || [], "name").filter(
      ({ token }) => isCampusView || token !== space_token,
    ),
  ];

  if (!campusToken) {
    return null;
  }

  return (
    <section className="AssociatedPropertiesSection">
      <header>
        <h3>
          {hasLoaded ?
            <>
              <SvgIcon name="buildings" />
              Associated {!isCampusView ? `with ${campusModel.get("name")}` : "properties"}
              <span className="subtext">
                {withUnits(properties.length, "property")}
                {!isCampusView ? " in campus" : ""}
              </span>
              {properties.length > 3 ?
                <Button
                  flavor="link"
                  onClick={() => openPanel(<AssociatedPropertiesPanel properties={properties} />)}
                >
                  View all
                </Button>
              : null}
            </>
          : "\xa0"}
        </h3>
        <Button flavor="link" onClick={() => launch(<AddPropertyToCampusModal />)}>
          Add a property
        </Button>
      </header>
      <ul>
        {(hasLoaded ?
          properties.slice(0, 3)
        : Array.from({ length: 3 }, () => ({}) as Campus | Space)
        ).map((property, i) => (
          <li key={property.token || i}>
            <PropertyLink {...property} {...{ hasLoaded, isLoading, hasErrored }} />
          </li>
        ))}
      </ul>
    </section>
  );
}
