import type { EmissionSummary, OnboardingSummary } from "@/js/models/OnboardingSummaryModel";

import Modal from "@/components/Modal";
import Table from "@/components/Table";

import { formatNumber, formatPercentage } from "@/js/utils/stringFormatter";

export default function AggregatedPropertyDataCompletenessModal({
  aggregatePropertyDetails,
  summary,
}: {
  aggregatePropertyDetails: OnboardingSummary["property_details"];
  summary: OnboardingSummary["summary"];
}) {
  return (
    <Modal title="Onboarding Progress" size="x-large">
      <Table
        head={[
          { content: "Property", sort: true },
          { content: "Area", sort: true, numeric: true },
          { content: "% Direct Emissions Onboarded", sort: true, numeric: true },
          { content: "% Indirect Emissions Onboarded", sort: true, numeric: true },
          { content: "% Total Emissions Onboarded", sort: true, numeric: true },
        ]}
        body={aggregatePropertyDetails.map(({ name, emission_summary }) => [
          name,
          {
            content: formatIfExists(
              getEmissionRow(emission_summary, "Total Emissions").area,
              formatNumber,
            ),
            compare: getEmissionRow(emission_summary, "Total Emissions").area,
          },
          {
            content: formatIfExists(
              getEmissionRow(emission_summary, "Direct Emissions").percentage_complete,
              formatPercentage,
            ),
            compare: getEmissionRow(emission_summary, "Direct Emissions").percentage_complete,
          },
          {
            content: formatIfExists(
              getEmissionRow(emission_summary, "Indirect Emissions").percentage_complete,
              formatPercentage,
            ),
            compare: getEmissionRow(emission_summary, "Indirect Emissions").percentage_complete,
          },
          {
            content: formatIfExists(
              getEmissionRow(emission_summary, "Total Emissions").percentage_complete,
              formatPercentage,
            ),
            compare: getEmissionRow(emission_summary, "Total Emissions").percentage_complete,
          },
        ])}
        itemsPerPage={5}
        totals={[
          "Totals",
          formatIfExists(summary.total_area, formatNumber),
          formatIfExists(summary.direct_emissions_percentage_complete, formatPercentage),
          formatIfExists(summary.indirect_emissions_percentage_complete, formatPercentage),
          formatIfExists(summary.percentage_complete, formatPercentage),
        ]}
      />
    </Modal>
  );
}

function getEmissionRow(emission_summary: EmissionSummary[] = [], name: string): EmissionSummary {
  return emission_summary.find((entry) => name === entry.name) || ({} as EmissionSummary);
}

function formatIfExists(value: number | null, formatter: (val: number) => string) {
  return typeof value === "number" ? formatter(value) : "--";
}
