import { useRouter as UseNextRouter } from "next/router";

import { useScreenshotJobContext } from "./useScreenshotContext";

// Custom useRouter. It is currently just used to allow the next js router query to be overridden in the ScreenshotJobContext
const useRouter = () => {
  const router = UseNextRouter();
  const screenshotJobContext = useScreenshotJobContext();
  const query = screenshotJobContext?.query || {};

  return { ...router, query: { ...router.query, ...query } };
};

export default useRouter;
