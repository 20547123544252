import type { EnergySource } from "@/js/models/EnergySourceModel";

import partition from "lodash/partition";
import sortBy from "lodash/sortBy";
import sumBy from "lodash/sumBy";
import { memo, useState } from "react";

import ButtonGroup from "@/components/Button/ButtonGroup";
import CambioCard from "@/components/CambioCard";
import ChartLegend from "@/components/Charts/ChartLegend";
import { PieChart, PieChartColors } from "@/components/Charts/PieChart";
import EmptyState from "@/components/EmptyState";
import TextOverflow from "@/components/TextOverflow";

import { ChartColors } from "@/js/constants/cambio";
import { capitalizeWords, formatNumber, formatPercentage } from "@/js/utils/stringFormatter";
import { unitFormatter } from "@/js/utils/unitFormatter";

import { useDashboardContext, useDashboardResources } from "../utils";
import EnergySourceDownloadButton from "./EnergySourceDownloadButton";

type View = "grid" | "total";

interface EnergySourceChartProps {
  /**
   * Source breakdown of either total or grid energy. A `breakdown` property is for further
   * bucketing energy types, whether in an "Other" category or in an onsite/offsite renewables
   * category.
   */
  data: {
    name: string;
    value: number;
    /** This is how we force colors for the total energy chart */
    color?: string;
    breakdown?: {
      name: string;
      value: number;
    }[];
  }[];
  hasLoaded: boolean;
  /** This number goes in the middle of the pie chart */
  percentRenewable: number;
  /** List of renewable sources from the breakdown */
  renewableSources: EnergySource[];
  /** Whether we're looking at the grid breakdown or total breakdown */
  view: View;
}

const MAX_ITEMS = 6;

/**
 * Shows an energy source breakdown across all properties or a single property. The chart has a
 * toggle for viewing grid-specific breakdowns as well as total energy breakdowns. The latter is
 * further broken down into renewable/non-renewable, and the renewables are broken down into
 * offsite and onsite generation. For the grid chart, a max 7 items are listed, and the rest are
 * put into an "other" bucket.
 */
export default memo(function EnergySourceCard() {
  const [view, setView] = useState<View>("total");
  const { propertyLoadingStates } = useDashboardContext();

  const { energySourceModel, hasLoaded, isLoading, hasErrored, hasInitiallyLoaded } =
    useDashboardResources(["energySource"], { energySourceGridOnly: view === "grid" });

  // order our energy sources in decreasing value; if we're looking at the grid, bucket anything
  // after the first MAX_ITEMS into an other bucket
  let energySources = sortByDescendingValue(
    energySourceModel.get("energy_sources")?.map((source) => ({
      ...source,
      name: capitalizeWords(source.source.replaceAll("_", " ")),
    })),
  );

  let [nonOtherSources, otherSource] = partition(energySources, ({ source }) => source !== "other");

  const [renewableSources, nonRenewableSources] = partition(
    energySources,
    ({ renewable }) => renewable,
  );
  const [onsiteRenewables, offsiteRenewables] =
    view === "total" ? partition(renewableSources, ({ onsite }) => onsite) : [];
  const totalEnergy = sumBy(energySources, "value");

  // use MAX_ITEMS + 1 so that the "other" bucket has at least two items. otherwise it's not very useful!
  if (view === "grid" && energySources.length > MAX_ITEMS + 1) {
    const otherBucketItems = nonOtherSources.slice(MAX_ITEMS).concat(otherSource);

    // because we've created a new "other" bucket that combines multiple buckets, we need to re-sort
    energySources = sortByDescendingValue(
      nonOtherSources.slice(0, MAX_ITEMS).concat({
        source: "other",
        name: "Other",
        value: sumBy(otherBucketItems, ({ value }) => value ?? 0),
        renewable: false,
        onsite: false,
        // the breakdown bucket is what nests items in the PieChart
        breakdown: sortByDescendingValue(otherBucketItems),
      } as EnergySource & { name: string }),
    );
  }

  const data =
    view === "grid" ? energySources : (
      [
        {
          name: "Non-renewable energy",
          value: sumBy(nonRenewableSources, "value"),
        },
        {
          name: "Onsite generated & consumed",
          color: ChartColors.LIME,
          value: sumBy(onsiteRenewables, "value"),
          breakdown: onsiteRenewables,
        },
        {
          name: "Offsite procured & consumed",
          color: ChartColors.LEMON,
          value: sumBy(offsiteRenewables, "value"),
          breakdown: offsiteRenewables,
        },
      ]
    );

  return (
    <CambioCard
      className="EnergySourceCard"
      title="Energy Source Breakdown"
      // @ts-ignore
      label={unitFormatter(energySourceModel.get("energy_sources_unit"))}
      hasErrored={hasErrored || propertyLoadingStates.hasErrored}
      isLoading={isLoading || propertyLoadingStates.isLoading}
      actionBar={
        <>
          <ButtonGroup
            items={[
              { key: "total", text: "Total" },
              { key: "grid", text: "Grid" },
            ]}
            selected={view}
            onClick={setView}
          />
          <EnergySourceDownloadButton />
        </>
      }
    >
      {hasInitiallyLoaded ?
        <MemoizedEnergySourceChart
          data={totalEnergy ? data : []}
          hasLoaded={hasLoaded}
          renewableSources={renewableSources}
          percentRenewable={
            totalEnergy ? (sumBy(renewableSources, "value") * 100) / totalEnergy : null
          }
          view={view}
        />
      : null}
    </CambioCard>
  );
});

/**
 * This content is memoized so that we can avoid rendering when we go from a loaded to a loading
 * state by changing the total/grid view. If we rendered, we'd see the old data rendered in the
 * new UI. This way the old data stays in the old UI until the new data comes in.
 */
const MemoizedEnergySourceChart = memo(
  ({ data, percentRenewable, renewableSources, view }: EnergySourceChartProps) =>
    !data.length ?
      <EmptyState message="No energy source data for this time period" />
    : <>
        <PieChart
          {...(view === "total" ? { colorFlavor: "lime-lemon", hasBlankFirstCell: true } : {})}
          data={data.filter((item) => view === "total" || item.value)}
          centeredMetric={
            typeof percentRenewable === "number" ? formatPercentage(percentRenewable, 0) : "--"
          }
          centeredMetricSubtitle={percentRenewable ? "Renewable" : ""}
          dataKey="value"
          hasLegend={false}
          // TODO: this is custom for this component, but I can easily see this
          // value/unit/percentage combo being extend to many other PieCharts (and maybe other
          // charts?). it requires some tricky styling to get the numbers to line up, so we
          // should extract this out into its own component.
          tooltipValueFormatter={(value, data) => (
            <>
              <span>
                {formatNumber(value)}&nbsp;<span className="unit">kBtu</span>
              </span>
              <span className="middot" />
              <span>{formatPercentage(data.percentage, data.percentage > 1 ? 0 : 1)}</span>
            </>
          )}
          tooltipLabelKey="name"
          nameKey="name"
        />
        <div>
          {view === "grid" ?
            <>
              <div className="renewable-totals">
                <h5>
                  <TextOverflow>Renewable energy</TextOverflow>
                </h5>
                <span>{formatNumber(sumBy(renewableSources, "value"))}</span>
              </div>
              <ChartLegend
                chartLegendItems={data.map((datum, i) => ({
                  ...datum,
                  value: formatNumber(datum.value),
                  color: PieChartColors[i],
                }))}
                vertical
                showValue
              />
            </>
          : <ul className="totals-legend">
              {[
                { label: "Non-renewable energy", value: data[0].value },
                { label: "Renewable energy", value: sumBy(renewableSources, "value") },
                { label: "Onsite generated & consumed", value: data[1].value },
                { label: "Offsite procured & consumed", value: data[2].value },
              ].map(({ label, value }) => (
                <li key={label}>
                  <h5>
                    <TextOverflow>{label}</TextOverflow>
                  </h5>
                  <span>{formatNumber(value)}</span>
                </li>
              ))}
            </ul>
          }
        </div>
      </>,
  (prevProps, nextProps) => prevProps.hasLoaded && !nextProps.hasLoaded,
);

function sortByDescendingValue<T extends { value: number }>(items: T[]): T[] {
  return sortBy(items, ({ value }) => -1 * value);
}
