import chroma from "chroma-js";
import dynamic from "next/dynamic";

import CambioCard from "@/components/CambioCard";
import { IconsType } from "@/components/SvgIcon";

import { formatDollarShorthand, formatPercentile } from "@/js/utils/stringFormatter";

import { ClimateRiskData } from "@/Api/generated";

const Gauge = dynamic((): any => import("@ant-design/plots").then((item) => item.Gauge), {
  ssr: false,
});

const createRangeColorString = (colors: string[], percent: number) => {
  const step = 1 / (colors.length - 1);
  let gradientString = "l(0)";
  let cutoffPassed = false;

  let indexWhenPassed = 0;
  let valueWhenPassed = 1;

  for (let i = 0; i < colors.length; i++) {
    if (indexWhenPassed === 0) {
      if (i * step > percent) {
        indexWhenPassed = i;
        valueWhenPassed = i * step;
        break;
      }
    }
  }

  colors.forEach((color: string, index: number) => {
    if (cutoffPassed) {
      return;
    }

    if (index * step > percent) {
      cutoffPassed = true;
      gradientString += ` ${1}:${chroma.scale(colors).mode("rgb")(percent).hex()}`;

      return;
    } else {
      gradientString += ` ${((index * step) / valueWhenPassed).toFixed(2)}:${color}`;
    }
  });

  return gradientString;
};

// Function to create the CSS linear gradient
const createLinearGradient = (colors: string[]) => {
  return `linear-gradient(to right, ${colors.join(", ")})`;
};

const color = ["#94eb52", "#ffd859", "#ff9359", "#ff6159"];

const GradientBar = ({
  buildingPercentile,
  minValue,
  maxValue,
}: {
  buildingPercentile: number;
  minValue: number;
  maxValue: number;
}) => {
  const buildingPercentilePosition =
    ((buildingPercentile - minValue) / (maxValue - minValue)) * 100;

  if (!buildingPercentilePosition && buildingPercentilePosition !== 0) {
    return null;
  }

  // Determine if we need to apply marginRight based on percentile position
  const shouldAlignRight = buildingPercentilePosition > 50;
  const textStyle =
    shouldAlignRight ?
      {
        marginRight: `${100 - buildingPercentilePosition}%`,
        marginLeft: "auto",
        color: "black",
        textAlign: "right" as const,
      }
    : {
        marginLeft: `${buildingPercentilePosition}%`,
        color: "black",
      };

  return (
    <div className="flex flex-col w-full mb-16">
      <div className="flex flex-col items-start z-10">
        {/* Use textStyle to conditionally apply the right margin */}
        <div className="text-[#727273] text-sm font-bold" style={textStyle}>
          {buildingPercentilePosition || buildingPercentilePosition === 0 ?
            `${formatPercentile(parseFloat(buildingPercentilePosition.toFixed(0)))} percentile`
          : ""}
        </div>

        <div className="text-[#727273] text-sm" style={textStyle}>
          This building
        </div>

        <div
          className="w-0.5 h-[10px] bg-[#727273]"
          style={{ marginLeft: buildingPercentilePosition + "%" }}
        ></div>
      </div>
      <div
        className="relative"
        style={{
          height: "40px",
          background: createLinearGradient(color),
        }}
      >
        <div
          className="absolute bg-[#727273] bottom-0 top-0 w-0.5 z-10"
          style={{ left: buildingPercentilePosition + "%" }}
        ></div>

        <div
          className="absolute bg-[#727273] h-2 rounded-full w-2"
          style={{
            left: `calc(${buildingPercentilePosition}% - 0.1875rem)`,
            bottom: `calc(0% - 0.25rem)`,
          }}
        ></div>
      </div>
    </div>
  );
};

export const ClimateRiskStatusCard = ({
  title,
  icon,
  riskType,

  climateRiskData,
}: {
  title: string;
  icon: IconsType;
  riskType: string;

  climateRiskData?: ClimateRiskData;
}) => {
  const minValue = 0;
  const maxValue = 100;

  const INSUFFICIENT_DATA = "Insufficient Data";
  const NOT_APPLICABLE = "Not Applicable";
  const NO_RATING = "No Rating";
  const NO_EXPECTED_ANNUAL_LOSSES = "No Expected Annual Losses";

  const doesNotApply = [INSUFFICIENT_DATA, NOT_APPLICABLE, NO_RATING, NO_EXPECTED_ANNUAL_LOSSES];

  const config = {
    percent: climateRiskData?.risk_score ? climateRiskData?.risk_score / 100 : 0,
    range: {
      color: createRangeColorString(color, climateRiskData?.risk_score / 100),
    },

    axis: false,
    innerRadius: 0.75,

    startAngle: Math.PI,
    endAngle: 2 * Math.PI,
    // @ts-ignore
    indicator: null,
    statistic: {
      title: {
        formatter: ({ percent }: { percent: any }) => {
          return percent ? formatPercentile(parseFloat((percent * 100).toFixed(0))) + "" : "--";
        },
        offsetY: -10,
        style: ({ percent }: { percent: any }) => {
          return {
            fontSize: "30px",
            lineHeight: 1,
            color: "black",
          };
        },
      },
      content: {
        offsetY: 0,
        style: {
          fontSize: "12px",
          color: "black",
        },
        formatter: () => "percentile",
      },
    },
  };

  return (
    <CambioCard title={title} icon={icon}>
      <div className="flex flex-row  my-4 ">
        <div className="flex flex-1 flex-col mx-8 w-full max-w-full overflow-clip">
          {/* @ts-ignore */}
          <div className="h-[200px]">
            <div className="text-lg  font-bold">Risk Index</div>
            <div className=" max-w-full  flex flex-col justify-end">
              <div style={{ width: "100%" }} className="">
                {/* @ts-ignore */}
                <Gauge {...config} height={150} />
              </div>
            </div>
          </div>

          <div className="text-center ">
            {climateRiskData?.risk_rating &&
              !doesNotApply.includes(climateRiskData?.risk_rating) && (
                <>
                  {" "}
                  This asset is at <strong>
                    {climateRiskData?.risk_rating?.toLowerCase()}
                  </strong>{" "}
                  risk for <strong>{riskType}</strong> according to U.S climate risk indexes.
                </>
              )}

            {[INSUFFICIENT_DATA, NO_RATING].includes(climateRiskData?.risk_rating) && (
              <span>
                Risk values for <strong>{riskType === "floods" ? "flooding" : riskType}</strong> not
                available for your asset due to insufficient physical risk source data.
              </span>
            )}

            {[NOT_APPLICABLE].includes(climateRiskData?.risk_rating) && (
              <>
                Risk values for <strong>{riskType === "floods" ? "flooding" : riskType}</strong> not
                applicable for this asset.
              </>
            )}
          </div>
        </div>

        <div className="bg-gray-500 self-stretch w-[1px]"></div>

        <div className="flex flex-1 flex-col mx-8 justify-start  h-full ">
          <div className="flex flex-col h-[200px] w-full justify-start">
            <div className="text-lg  font-bold">Expected Annual Loss ($)</div>

            {(
              climateRiskData?.eal_rating &&
              climateRiskData?.eal_building_loss_estimated_amount?.amount_text &&
              !doesNotApply.includes(climateRiskData?.eal_rating)
            ) ?
              <div className="items-end flex flex-1  justify-center">
                <GradientBar
                  buildingPercentile={climateRiskData?.eal_score}
                  minValue={minValue}
                  maxValue={maxValue}
                />
              </div>
            : ![NOT_APPLICABLE].includes(climateRiskData?.eal_rating) ?
              <div className="flex flex-1 justify-center items-center w-full text-center">
                <span>
                  Expected Annual Loss from{" "}
                  <strong>{riskType === "floods" ? "flooding" : riskType}</strong> is not available
                  for your asset due to insufficient physical risk source data.
                </span>
              </div>
            : <div className="flex flex-1 justify-center items-center w-full text-center">
                <div>
                  Expected Annual Loss from
                  <strong>{" " + (riskType === "floods" ? "flooding" : riskType) + " "}</strong>
                  is not applicable for this asset.
                </div>
              </div>
            }
          </div>

          {climateRiskData?.eal_rating && !doesNotApply.includes(climateRiskData?.risk_rating) && (
            <div className="text-center flex-1">
              {climateRiskData?.eal_rating &&
                !doesNotApply.includes(climateRiskData?.eal_rating) &&
                climateRiskData?.eal_building_loss_estimated_amount && (
                  <>
                    This asset is estimated to lose{" "}
                    <strong>
                      $1 per
                      {" " +
                        formatDollarShorthand(
                          climateRiskData?.eal_building_loss_estimated_amount?.amount_minor / 100,
                        )}
                    </strong>{" "}
                    in value per year from <strong>{riskType}</strong>.
                  </>
                )}
            </div>
          )}
        </div>
      </div>
    </CambioCard>
  );
};
