import type { ReactNode } from "react";

import SvgIcon from "@/components/SvgIcon";

import { classnames } from "@/js/utils/cambio";

interface BannerProps {
  type?: "about" | "tip";
  children: ReactNode;
  title: string;
}

export default function Banner({ type = "about", title, children }: BannerProps) {
  return (
    <div className={classnames("Banner", { [type]: type !== "about" })}>
      <header>
        <SvgIcon name="info-circle" />
        <h5>{title}</h5>
      </header>
      {children}
    </div>
  );
}
