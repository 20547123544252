import type { EnergySource } from "@/js/models/EnergySourceModel";

import dayjs from "dayjs";
import omit from "lodash/omit";
import sortBy from "lodash/sortBy";
import { useEffect, useState } from "react";
import { ExecutorFunction, useResources } from "resourcerer";

import { CardDownloadButton } from "@/components/Button/CardDownloadButton";
import { FilterTypes } from "@/components/Filters";

import { convertToCsvAndDownload } from "@/js/utils/csvCreation";
import { capitalizeWords, formatPercentage } from "@/js/utils/stringFormatter";

import { useAppContext } from "@/layouts/AppLayout/AppContext";

import { getFiltersQueryParams, useDashboardContext } from "../../utils";

// @ts-ignore
const getResources: ExecutorFunction<"energySource"> = (params: {
  filters: Record<FilterTypes, string>;
  shouldFetch: boolean;
}) => ({
  gridEnergySource: {
    params: { grid_only: true, ...omit(params, "shouldFetch", "filters"), ...params.filters },
    resourceKey: "energySource",
    dependsOn: !!params.shouldFetch,
  },
  totalEnergySource: {
    params: { ...omit(params, "shouldFetch", "filters"), ...params.filters },
    resourceKey: "energySource",
    dependsOn: !!params.shouldFetch,
  },
});

/**
 * Because we aren't guaranteed to have both total and grid sources already fetched when the user
 * clicks the download button and because they want to see both downloaded on the same sheet, we
 * need have a more complicated custom download button component. Once the user clicks the button,
 * we fetch both grid and total (they probably won't be in the cache because we don't ask for
 * benchmarks). Once they've loaded, we can compile and download the CSV.
 */
export default function EnergySourceDownloadButton() {
  const [shouldFetch, setShouldFetch] = useState(false);
  const { organizationId, organizationName } = useAppContext();
  const { property, propertyLoadingStates, filters, dateRange, spaceId } = useDashboardContext();
  // @ts-ignore
  const { gridEnergySourceModel, totalEnergySourceModel, hasLoaded, isLoading } = useResources(
    // @ts-ignore
    getResources,
    {
      organization_id: organizationId,
      start_date: dayjs(dateRange[0]).format("YYYY-MM-DD"),
      end_date: dayjs(dateRange[1]).format("YYYY-MM-DD"),
      filters: getFiltersQueryParams(filters),
      ...(spaceId ? { spaces: spaceId } : {}),
      shouldFetch,
    },
  );

  const totalSources = (totalEnergySourceModel.get("energy_sources") || []) as EnergySource[];
  const gridSources = (gridEnergySourceModel.get("energy_sources") || []) as EnergySource[];

  useEffect(() => {
    if (hasLoaded) {
      const total = totalSources.reduce((memo, entry) => memo + (entry.value || 0), 0);
      const gridTotal = gridSources.reduce((memo, entry) => memo + (entry.value || 0), 0);

      const energySources = sortBy(
        totalSources.map((entry) => ({
          ...entry,
          gridValue: gridSources.find(
            ({ source, value }) => source === entry.source && !entry.onsite,
          )?.value,
        })),
        ({ value }) => -1 * value,
      );

      convertToCsvAndDownload(
        [
          {
            title: "Source",
            processValue: (entry) => {
              const source = capitalizeWords((entry.source || "").replaceAll("_", " "));

              return ["wind", "solar"].includes(entry.source) && entry.onsite ?
                  `${source} (onsite)`
                : source;
            },
          },

          { title: `Total (kBtu)`, key: "value", processValue: (value) => value.toFixed(1) },
          {
            title: `Total %`,
            key: "value",
            processValue: (value) => formatPercentage((value / total) * 100, 1),
          },
          {
            title: `Grid (kBtu)`,
            key: "gridValue",
            processValue: (value) => value?.toFixed(1) || 0,
          },
          {
            title: `Grid %`,
            key: "gridValue",
            processValue: (value) =>
              formatPercentage(value && gridTotal ? (value / gridTotal) * 100 : 0, 1),
          },
        ],
        energySources,
        (property ? property.name : organizationName) + " Energy Source Breakdown",
      );

      setShouldFetch(false);
    }
  }, [hasLoaded]);

  return (
    <CardDownloadButton
      disabled={isLoading || !propertyLoadingStates.hasLoaded}
      name="Energy Source Breakdown"
      onClick={() => setShouldFetch(true)}
    />
  );
}
