import type { ExecutorFunction } from "resourcerer";

import { useRouter } from "next/router";
import { useResources } from "resourcerer";

import Banner from "@/components/Banner";
import SidePanel from "@/components/SidePanel";

import { ClimateRiskStatusCard } from "../ClimateRiskStatusCard";

const getResources: ExecutorFunction<
  "climateRisks",
  { organization_token: string; space_token: string }
> = ({ organization_token, space_token }) => ({
  climateRisks: {
    path: {
      orgToken: organization_token,
      propertyToken: space_token,
    },
  },
});

export const ClimateResilienceDrawer = () => {
  const router = useRouter();
  const { climateRisksCollection } = useResources(
    getResources,
    router.query as { organization_token: string; space_token: string },
  );

  return (
    <SidePanel className="ClimateResilienceDrawer" size="medium" title="Physical Climate Risk">
      <ClimateRiskStatusCard
        title="Hurricane"
        riskType="hurricanes"
        icon="hurricane"
        climateRiskData={climateRisksCollection.findWhere({ category: "HURRICANE" })?.toJSON()}
      />
      <ClimateRiskStatusCard
        title="Wildfire"
        riskType="wildfires"
        icon="wildfire"
        climateRiskData={climateRisksCollection.findWhere({ category: "WILDFIRE" })?.toJSON()}
      />
      <ClimateRiskStatusCard
        title="Earthquake"
        riskType="earthquakes"
        icon="earthquake"
        climateRiskData={climateRisksCollection.findWhere({ category: "EARTHQUAKE" })?.toJSON()}
      />

      <ClimateRiskStatusCard
        title="Flood"
        riskType="floods"
        icon="flood"
        climateRiskData={climateRisksCollection
          .findWhere({ category: "COASTAL_FLOODING" })
          ?.toJSON()}
      />
      <ClimateRiskStatusCard
        title="Heatwave"
        riskType="heatwaves"
        icon="heatwave"
        climateRiskData={climateRisksCollection.findWhere({ category: "HEAT_WAVE" })?.toJSON()}
      />

      <Banner title="About">
        <p>
          Physical climate risk describes the adverse impacts of climate-related events and
          long-term trends on your portfolio. These risks encompass extreme weather events like
          hurricanes, floods, wildfires, and droughts, as well as longer-term shifts in temperature
          and precipitation patterns.
        </p>
        <p>
          Cambio tracks physical risk factors using FEMA’s official geospatial tool for physical
          climate risk tracking for U.S. properties. The <strong>Risk Index</strong> metric tracks
          physical risk from each climate-related impact on a scale of 1 to 100, where 100 is
          highest-risk. The <strong>Expected Annual Loss (EAL)</strong> metric estimates average
          yearly loss in property value due to a climate-related risk factor.
        </p>
      </Banner>
    </SidePanel>
  );
};
