import CambioCard from "@/components/CambioCard";

export enum NotEnoughDataMessageTypes {
  SELECT_MORE_TIME = "We need more data to run our analytics. Please select a time period that covers at least three months.",
  NOT_ENOUGH_DATA = "You don't have data in the selected time period. Stick with Cambio to build up your database.",
  NOT_ENOUGH_DATA_LAST_PERIOD = "You don't have enough data last period",
}

const NotEnoughDataCard = ({
  timePeriodTooSmall,
}: {
  timePeriodTooSmall?: NotEnoughDataMessageTypes;
}) => {
  return (
    <CambioCard className="NotEnoughDataCard">
      <span>!!</span>
      Oh no!{" " + timePeriodTooSmall}
    </CambioCard>
  );
};

export default NotEnoughDataCard;
