import dayjs from "dayjs";

import Select from "@/components/Select";

import { useAppContext } from "@/layouts/AppLayout/AppContext";

import { getHistoricalDateRange, getSelectedYear, minDay } from "../utils";

/**
 * Dropdown to render just the years that the customer can toggle through as a coarse replacement
 * to our DateRangePicker. The defaultMetricsDateRange also represents the customer's historical
 * data date range. So we only populate the dropdown with the years from which they have data.
 *
 * Selecting the current year calls the callback with not the end of year date (which would be the
 * future) but the current day.
 */
export default function AnnualizedDataDropdown({
  dateRange,
  onChangeMetricDateRange,
}: {
  dateRange: [string, string];
  onChangeMetricDateRange: (range: [string, string]) => void;
}) {
  const { featureConfigurations } = useAppContext();
  const historicalDateRange = getHistoricalDateRange(featureConfigurations);

  return (
    <Select
      icon="calendar"
      onSelect={(year) =>
        onChangeMetricDateRange([
          dayjs(year, "YYYY").startOf("year").toISOString(),
          minDay(
            dayjs(year, "YYYY").endOf("year").endOf("year"),
            dayjs().startOf("day"),
          ).toISOString(),
        ])
      }
      options={Array.from(
        {
          length:
            dayjs(historicalDateRange[1]).get("year") -
            dayjs(historicalDateRange[0]).get("year") +
            1,
        },
        (_, i) => "" + (dayjs(historicalDateRange[1]).get("year") - i),
      ).map((year) => ({ value: year, display: year }))}
      size="small"
      // value will be of the current metric date range, whereas the domain is from
      // the default metrics date range, which covers all historical data
      value={getSelectedYear(dateRange)}
    />
  );
}
