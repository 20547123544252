import CambioCard from "@/components/CambioCard";
import { PropertyImage } from "@/components/PropertyCard";

import { useDashboardResources } from "../utils";

/**
 * CambioCard that simply shows the property image.
 */
export default function PropertyImageCard() {
  const { isLoading, hasErrored, hasLoaded, propertyModel } = useDashboardResources(["property"], {
    dateRange: [] as any,
  });

  return (
    <CambioCard hasErrored={hasErrored} isLoading={isLoading} className="PropertyImageCard">
      {hasLoaded ?
        <PropertyImage src={propertyModel.get("image")} />
      : null}
    </CambioCard>
  );
}
