import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

import { Presets } from "@/components/DateRangePicker/constants";

import { withUnits } from "@/js/utils/stringFormatter";

dayjs.extend(duration);

type DateRange = [string, string];

type PresetLabel = {
  currentLabel: string;
  previousLabel: string;
  currentPeriod?: string;
  previousPeriod?: string;
};

// Formats a date range as a string
function formatDateRange(
  // the start date of the range
  start: dayjs.Dayjs,
  // the end date of the range
  end: dayjs.Dayjs,
): string {
  return `${start.format("MMM D, YYYY")} - ${end.format("MMM D, YYYY")}`;
}

// Checks if the date range spans an entire year
function isWholeYearRange(
  // the start date of the range
  start: dayjs.Dayjs,
  // the end date of the range
  end: dayjs.Dayjs,
): boolean {
  return (
    start.month() === 0 &&
    start.date() === 1 &&
    end.month() === 11 &&
    end.date() === 31 &&
    end.year() === start.year()
  );
}

// Calculates the duration between two dates and returns it as a formatted string
function calculateDuration(
  // the start date of the range
  start: dayjs.Dayjs,
  // the end date of the range
  end: dayjs.Dayjs,
): string {
  const months = end.diff(start, "month");
  const days = end.diff(start.add(months, "month"), "day");

  return [
    { number: months, label: "month" },
    { number: days, label: "day" },
  ]
    .filter(({ number }) => !!number)
    .map(({ number, label }) => withUnits(number, label))
    .join(", ");
}

// Formats the previous period date range to have the same number of days as the current period
function formatPreviousPeriod(
  // the start date of the current range
  start: dayjs.Dayjs,
  // the end date of the current range
  end: dayjs.Dayjs,
): string {
  const periodLength = end.diff(start, "day");
  const previousEnd = start.subtract(1, "day");
  const previousStart = previousEnd.subtract(periodLength, "day");

  return formatDateRange(previousStart, previousEnd);
}

function formatCustomRangeLabel(
  // the date range to format
  range: DateRange,
  // whether the range is year over year comparison
  isYearOverYear: boolean,
): PresetLabel {
  const start = dayjs(range[0]);
  const end = dayjs(range[1]);

  if (isWholeYearRange(start, end)) {
    return {
      currentLabel: `(${start.year()})`,
      previousLabel: `(${start.year() - 1})`,
      currentPeriod: formatDateRange(start, end),
      previousPeriod: formatDateRange(start.add(-1, "year"), end.add(-1, "year")),
    };
  }

  const currentPeriod = formatDateRange(start, end);
  const duration = calculateDuration(start, end);

  return {
    currentLabel: duration,
    previousLabel: duration,
    currentPeriod,
    previousPeriod:
      isYearOverYear ?
        formatDateRange(start.add(-1, "year"), end.add(-1, "year"))
      : formatPreviousPeriod(start, end),
  };
}

export function presetToLabel(
  preset: Presets,
  dateRange: DateRange,
  isYearOverYear: boolean,
): PresetLabel {
  const start = dayjs(dateRange[0]);
  const end = dayjs(dateRange[1]);
  const avgDate = dayjs((start.valueOf() + end.valueOf()) / 2);
  const prevYearDate = avgDate.subtract(1, "year");

  const defaultPreviousPeriod = formatPreviousPeriod(start, end);
  const labels = {
    currentLabel: "",
    previousLabel: "",
    currentPeriod: formatDateRange(start, end),
    previousPeriod:
      isYearOverYear ?
        formatDateRange(start.add(-1, "year"), end.add(-1, "year"))
      : defaultPreviousPeriod,
  };

  const setQuarterLabels = () => {
    const currentQuarter = start.format("Q");
    const previousQuarter = start.subtract(1, "quarter").format("Q");
    const previousQuarterStart = start.add(-1, "quarter").startOf("quarter");
    const previousQuarterEnd = end.add(-1, "quarter").endOf("quarter");

    labels.currentLabel = `(Q${currentQuarter} '${avgDate.format("YY")})`;
    labels.previousLabel =
      isYearOverYear ?
        `(Q${currentQuarter} '${prevYearDate.format("YY")})`
      : `(Q${previousQuarter} '${previousQuarterStart.format("YY")})`;
    labels.previousPeriod =
      isYearOverYear ?
        formatDateRange(start.add(-1, "year"), end.add(-1, "year"))
      : formatDateRange(previousQuarterStart, previousQuarterEnd);
  };

  switch (preset) {
    case Presets.LAST_TWELVE_MONTHS:
      labels.currentLabel = "12 months";
      labels.previousLabel = "12 months";
      break;
    case Presets.YEAR_TO_DATE_Q1:
    case Presets.LAST_YEAR_Q1:
    case Presets.YEAR_TO_DATE_Q2:
    case Presets.LAST_YEAR_Q2:
    case Presets.YEAR_TO_DATE_Q3:
    case Presets.LAST_YEAR_Q3:
    case Presets.LAST_YEAR_Q4:
      setQuarterLabels();
      break;
    case Presets.LAST_YEAR:
      labels.currentLabel = `(${avgDate.year()})`;
      labels.previousLabel = `(${avgDate.subtract(1, "year").year()})`;
      break;
    case Presets.TWO_YEARS_AGO:
      labels.currentLabel = `(${avgDate.year()})`;
      labels.previousLabel = `(${prevYearDate.year()})`;
      break;
    case Presets.YEAR_TO_DATE:
    case Presets.CUSTOM:
    default:
      return formatCustomRangeLabel(dateRange, isYearOverYear);
  }

  return labels;
}
