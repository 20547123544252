import type { FilterTypes } from "@/components/Filters";
import type { CertificationTypes } from "@/js/models/AggregateCertificationsCollection";
import type { ExecutorFunction } from "resourcerer";

import sortBy from "lodash/sortBy";
import sumBy from "lodash/sumBy";
import { useResources } from "resourcerer";

import CambioCard from "@/components/CambioCard";
import MultiProgressBar from "@/components/ProgressBar/MultiProgressBar";

import { formatPercentage } from "@/js/utils/stringFormatter";

import { useAppContext } from "@/layouts/AppLayout/AppContext";

import { getFiltersQueryParams, useDashboardContext } from "../utils";

const getResources: ExecutorFunction<
  "aggregateCertifications",
  { organizationToken: string; filters: Record<FilterTypes, string> }
> = ({ organizationToken, filters }) => ({
  aggregateCertifications: {
    params: { organization_token: organizationToken, ...filters },
  },
});

const CertificationsConfig: Record<CertificationTypes, { title: string; sortOrder?: string[] }> = {
  LEED: { title: "LEED", sortOrder: ["Platinum", "Gold", "Silver", "Certified"] },
  EnergyStar: { title: "EnergyStar Certified" },
};

/**
 * Certifications card across a portfolio or subportfolio. Where applicable, they are broken down
 * into levels, so a MultiProgressBar is used.
 */
export default function AggregatedCertificationsCard() {
  const { organizationToken } = useAppContext();
  const { filters } = useDashboardContext();
  const { aggregateCertificationsCollection, isLoading, hasErrored } = useResources(getResources, {
    organizationToken,
    filters: getFiltersQueryParams(filters),
  });

  return (
    <CambioCard
      className="AggregatedCertificationsCard"
      title="Certificates & Ratings"
      label="% of sq ft"
      isLoading={isLoading}
      hasErrored={hasErrored}
    >
      <ul key={organizationToken}>
        {(["LEED", "EnergyStar"] as CertificationTypes[])
          .map((type) => ({
            type,
            ...CertificationsConfig[type],
            ...aggregateCertificationsCollection
              .toJSON()
              .find((cert) => cert.certification_type === type),
          }))
          .map(({ type, title, total_floor_area, certification_levels = [] }) => (
            <li key={type}>
              <h5>{title}</h5>
              <div>
                <MultiProgressBar
                  items={sortBy(certification_levels, ({ level }, i) =>
                    CertificationsConfig[type]?.sortOrder ?
                      CertificationsConfig[type]?.sortOrder.indexOf(level)
                    : i,
                  )?.map(({ level, floor_area }) => ({
                    display: level,
                    value: floor_area,
                  }))}
                  title={title}
                  total={total_floor_area}
                  withTooltip={!!certification_levels.length}
                />
                <h4>
                  {certification_levels.length ?
                    formatPercentage(
                      total_floor_area ?
                        (sumBy(certification_levels, "floor_area") * 100) / total_floor_area
                      : 0,
                      0,
                    )
                  : "--"}
                </h4>
              </div>
            </li>
          ))}
      </ul>
    </CambioCard>
  );
}
