import type { ExecutorFunction } from "resourcerer";

import partition from "lodash/partition";
import { useResources } from "resourcerer";

import { CardDownloadButton } from "@/components/Button/CardDownloadButton";
import CambioCard from "@/components/CambioCard";
import Delta from "@/components/Delta";
import { FilterTypes } from "@/components/Filters";
import MetricValue from "@/components/MetricValue";
import ProgressBar, { ProgressBarColors } from "@/components/ProgressBar";

import { classnames } from "@/js/utils/cambio";
import { convertToCsvAndDownload } from "@/js/utils/csvCreation";
import { formatPercentage } from "@/js/utils/stringFormatter";

import { ScoreComparison } from "@/Api/generated";
import { useAppContext } from "@/layouts/AppLayout/AppContext";

import { getFiltersQueryParams, getSelectedYear, useDashboardContext } from "../utils";

const getResources: ExecutorFunction<
  "surveyScore",
  { property_token: string; year: string; orgToken: string; filters: Record<FilterTypes, string> }
> = ({ property_token, year, orgToken, filters }) => ({
  surveyScore: {
    params: {
      year,
      ...filters,
      ...(property_token ? { property_token } : {}),
    },
    path: { orgToken },
  },
});

export default function SurveyScoreCard() {
  const { organizationToken, organizationName } = useAppContext();
  const { property, dateRange, filters } = useDashboardContext();

  const { surveyScoreModel, isLoading, hasErrored, hasInitiallyLoaded } = useResources(
    getResources,
    {
      property_token: property?.token,
      year: getSelectedYear(dateRange),
      orgToken: organizationToken,
      filters: getFiltersQueryParams(filters),
    },
  );

  const [[totalScore = {} as ScoreComparison], otherScores] = partition(
    surveyScoreModel.get("scores"),
    ({ title }) => title === "Total Score",
  );
  const formatSurveyPercentage = (val: number): string =>
    val ? formatPercentage(Math.round(val), 0) : "--";

  const getEmptyState = (message: string) => (
    <div className="no-scorecard">
      <img src="/images/surveys/empty-scorecard.svg" />
      <h3>Total score</h3>
      <p>{message}</p>
    </div>
  );

  return (
    <CambioCard
      className={classnames("SurveyScoreCard", {
        "no-delta":
          hasInitiallyLoaded &&
          !surveyScoreModel.get("scores").some((score) => score.delta_from_last_year),
      })}
      title={surveyScoreModel.get("name")}
      isLoading={isLoading}
      hasErrored={hasErrored}
      actionBar={
        <CardDownloadButton
          name="Survey Score"
          onClick={() => {
            convertToCsvAndDownload(
              [
                { title: "Title", key: "title" },
                { title: "Current %", key: "current_value", processValue: formatSurveyPercentage },
                {
                  title: "Previous %",
                  key: "previous_value",
                  processValue: formatSurveyPercentage,
                },
                {
                  title: "Change %",
                  key: "delta_from_last_year",
                  processValue: formatSurveyPercentage,
                },
              ],
              otherScores.concat(totalScore),
              `${property ? property.name : organizationName} ${surveyScoreModel.get(
                "name",
              )}, ${getSelectedYear(dateRange)}`,
            );
          }}
        />
      }
    >
      {hasInitiallyLoaded ?
        !surveyScoreModel.get("name") ?
          getEmptyState("We are unable to display survey score")
        : !surveyScoreModel.get("scores")?.length ?
          getEmptyState("We are still awaiting results from this survey")
        : <>
            <div className="totals">
              <h6>Current year</h6>
              <h6>Vs. Prior year</h6>
              <MetricValue size="large">
                {formatSurveyPercentage(totalScore.current_value)}
              </MetricValue>
              <span className="previous-score">
                <span>{formatSurveyPercentage(totalScore.previous_value)}</span>
                {totalScore.delta_from_last_year ?
                  <Delta value={Math.round(totalScore.delta_from_last_year)} />
                : null}
              </span>
            </div>
            <ul>
              {otherScores
                .slice(0, 5)
                .map(({ title, current_value, previous_value, delta_from_last_year }, i) => (
                  <li key={title}>
                    <span className="current-score">
                      <span>
                        <h5>{title}</h5>
                        <ProgressBar
                          color={ProgressBarColors[i]}
                          progress={(current_value || 0) / 100}
                        />
                      </span>
                      <MetricValue>{formatSurveyPercentage(current_value)}</MetricValue>
                    </span>
                    <span className="previous-score">
                      <span>{formatSurveyPercentage(previous_value)}</span>
                      {delta_from_last_year ?
                        <Delta value={Math.round(delta_from_last_year)} />
                      : null}
                    </span>
                  </li>
                ))}
            </ul>
          </>

      : null}
    </CambioCard>
  );
}
