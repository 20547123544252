import Button from "@/components/Button";
import Modal from "@/components/Modal";

import { loadScripts } from "@/js/core/analytics";
import { getEffectiveUser } from "@/js/core/coreResources";
import useModalContext from "@/js/hooks/useModalContext";

export default function AddPropertyToCampusModal() {
  const { end } = useModalContext();

  return (
    <Modal
      hero="/images/add-building.svg"
      title="Add property to this campus"
      footer={
        <Button
          flavor="primary"
          onClick={() => {
            loadScripts.intercom(getEffectiveUser().toJSON());
            window.Intercom("show");
            end();
          }}
        >
          Contact Support
        </Button>
      }
    >
      <p>
        Our Support Team can help you add a property to this campus. Please allow us up to 2 to 3
        business days to respond to your request.
      </p>
    </Modal>
  );
}
