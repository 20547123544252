import Link from "next/link";

import { PropertyImage } from "@/components/PropertyCard";
import Tag from "@/components/Tag";

import { getEmptyCell, getLoadingCell } from "@/js/utils/partials";
import { getAddressShorthand } from "@/js/utils/property";
import { withUnits } from "@/js/utils/stringFormatter";

import { Campus, Space } from "@/Api/generated";

import { isCampus } from "../utils";

export default function PropertyLink({
  image,
  name,
  sub_portfolio_token,
  token,
  // will only be present if a campus
  spaces,
  hasLoaded = true,
  hasErrored,
  isLoading,
  onClick,
  ...property
}: Partial<Campus | Space> & {
  onClick?: () => void;
  hasLoaded?: boolean;
  isLoading?: boolean;
  hasErrored?: boolean;
  spaces?: Space[];
}) {
  return (
    <Link
      className="PropertyLink"
      href={hasLoaded ? `/${sub_portfolio_token}/properties/${token}/overview` : ""}
      onClick={onClick}
    >
      {hasLoaded ?
        <PropertyImage src={image} />
      : <span className="loading-img" />}
      <span className="content">
        <p>
          {isLoading ?
            getLoadingCell()
          : hasErrored ?
            getEmptyCell()
          : name}
        </p>
        <span>
          {isLoading ?
            getLoadingCell()
          : hasErrored ?
            getEmptyCell()
          : isCampus(token) ?
            withUnits(spaces.length, "property")
          : getAddressShorthand(property.address)}
        </span>
      </span>
      {isCampus(token) ?
        <Tag type="campus" />
      : null}
    </Link>
  );
}
