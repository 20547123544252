import type { ExecutorFunction } from "resourcerer";

import { useEffect, useState } from "react";
import { useResources } from "resourcerer";

import Button from "@/components/Button";
import IconButton from "@/components/Button/IconButton";
import CambioCard from "@/components/CambioCard";
import HovercardToggle from "@/components/HovercardToggle";
import SelectableList from "@/components/SelectableList";
import Table from "@/components/Table";
import Tag from "@/components/Tag";

import useModalContext from "@/js/hooks/useModalContext";
import useRouter from "@/js/hooks/useRouter";
import useSidePanelContext from "@/js/hooks/useSidePanelContext";
import { getLoadingCell } from "@/js/utils/partials";

import { PropertyCertificationGet } from "@/Api/generated/models/PropertyCertificationGet";
import { useAppContext } from "@/layouts/AppLayout/AppContext";

import { CertificationsAddDrawer } from "../CertificationsAddDrawer";
import { CertificationsEditDrawer } from "../CertificationsEditDrawer";
import DeleteCertificationModal from "../DeleteCertificationModal";
import { useDashboardContext } from "../utils";

type CertificationTypes = "EnergyStar" | "LEED";

export const certificationImages: Record<CertificationTypes, string> = {
  EnergyStar: "/images/certifications/energyStarIcon.svg",
  LEED: "/images/certifications/leedIcon.svg",
};

export const getResources: ExecutorFunction<
  "certifications",
  { subPortfolioToken: string; propertyToken: string }
> = ({ subPortfolioToken, propertyToken }) => ({
  certifications: {
    path: {
      subPortfolioToken,
      propertyToken,
    },
  },
});

export default function CertificationsCard() {
  const { organizationToken, featureConfigurations } = useAppContext();
  const { space_token } = useRouter().query;
  const {
    certificationsCollection,
    refetch,
    isLoading,
    hasErrored,
    hasLoaded,
    hasInitiallyLoaded,
  } = useResources(getResources, {
    subPortfolioToken: organizationToken,
    propertyToken: space_token as string,
  });
  const { property } = useDashboardContext();

  function filterCertifications(certifications: CertificationTypes[]) {
    return certifications.filter((certificationType) => {
      const cert = certificationsCollection
        .toJSON()
        .find((cert) => cert.certification_type === certificationType);

      return (
        !featureConfigurations.TEMP_EDITABLE_CERTIFICATIONS_ENABLED ||
        (cert && (cert.status || cert.last_certification_date))
      );
    });
  }

  // Filtered certifications based on the feature flag and non-null values for status or date
  const filteredCertificationTypes = filterCertifications([
    "LEED",
    "EnergyStar",
  ] as CertificationTypes[]);

  const [originalCertificationTypes, setOriginalCertificationTypes] = useState([]);

  const { openPanel } = useSidePanelContext();

  const tableHead = [
    "Certification",
    "Status",
    "Last Certification Date",
    // Conditionally add "Actions" column if the feature is enabled
    ...(featureConfigurations.TEMP_EDITABLE_CERTIFICATIONS_ENABLED ? [""] : []),
  ];

  useEffect(() => {
    if (hasInitiallyLoaded) {
      setOriginalCertificationTypes(filteredCertificationTypes);
    }
  }, [hasInitiallyLoaded]);

  return (
    <CambioCard
      className="CertificationsCard"
      title="Certifications"
      hasErrored={hasErrored}
      actionBar={
        // Only show the "Add" button if there are certification types left to add
        // Since we only have two certification types, we can check if the length is 1
        (
          featureConfigurations.TEMP_EDITABLE_CERTIFICATIONS_ENABLED &&
          filteredCertificationTypes.length === 1
        ) ?
          <Button
            flavor="link"
            icon="plus"
            onClick={() => {
              openPanel(
                <CertificationsAddDrawer
                  hasEnergyStar={filteredCertificationTypes.includes("EnergyStar")}
                  hasLEED={filteredCertificationTypes.includes("LEED")}
                  propertyName={property.name}
                />,
              );
            }}
          >
            Add
          </Button>
        : null
      }
    >
      {filteredCertificationTypes.length > 0 && (
        <Table
          copyable={false}
          head={tableHead}
          body={filteredCertificationTypes
            .map((certification_type) => ({
              certification_type,
              ...certificationsCollection
                .toJSON()
                .find((cert) => cert.certification_type === certification_type),
            }))
            .map((certification) => [
              <span className="identifier" key={certification.certification_type}>
                <img
                  src={certificationImages[certification.certification_type as CertificationTypes]}
                />
                {certification.certification_type}
                {(
                  !originalCertificationTypes.includes(
                    certification.certification_type as CertificationTypes,
                  )
                ) ?
                  <Tag text="New" color="blue"></Tag>
                : null}
              </span>,
              isLoading ? getLoadingCell() : certification.status || "--",
              isLoading ? getLoadingCell() : certification.last_certification_date || "--",
              ...(featureConfigurations.TEMP_EDITABLE_CERTIFICATIONS_ENABLED ?
                [
                  <CertificationOverflowMenu
                    key={`menu-${certification.certification_type}`}
                    certification={certification}
                    propertyToken={space_token as string}
                    subPortfolioToken={organizationToken}
                    refetch={() => {
                      refetch(["certifications"]);
                    }}
                  />,
                ]
              : []),
            ])}
        />
      )}

      {(
        hasLoaded &&
        featureConfigurations.TEMP_EDITABLE_CERTIFICATIONS_ENABLED &&
        filteredCertificationTypes.length === 0
      ) ?
        <div className="empty-state">
          <img src="/images/certifications/no-certification.svg" alt="No Certifications" />
          <h4>No Certifications Found</h4>
          <p>This property has no certifications associated with it</p>
          <Button
            flavor="link"
            icon="plus"
            onClick={() => {
              openPanel(
                <CertificationsAddDrawer
                  hasEnergyStar={filteredCertificationTypes.includes("EnergyStar")}
                  hasLEED={filteredCertificationTypes.includes("LEED")}
                  propertyName={property.name}
                />,
              );
            }}
          >
            Add a certification
          </Button>
        </div>
      : null}
    </CambioCard>
  );
}

const CertificationOverflowMenu = ({
  certification,
  propertyToken,
  subPortfolioToken,
  refetch,
}: {
  certification: PropertyCertificationGet;
  propertyToken: string;
  subPortfolioToken: string;
  refetch: () => void;
}) => {
  const { openPanel, closePanel } = useSidePanelContext();
  const { launch } = useModalContext();
  const { property } = useDashboardContext();

  return (
    <HovercardToggle
      contents={
        <SelectableList
          items={[
            {
              key: "edit",
              display: "Edit certification",
              onClick: () => {
                openPanel(
                  <CertificationsEditDrawer
                    certification={certification}
                    propertyName={property.name}
                  />,
                );
              },
            },
            {
              key: "delete",
              display: "Delete certification",
              onClick: () => {
                launch(
                  <DeleteCertificationModal
                    certificationId={certification.certification_id}
                    propertyName={property.name}
                    propertyToken={propertyToken}
                    subPortfolioToken={subPortfolioToken}
                    certificationType={certification.certification_type as CertificationTypes}
                    refetch={refetch}
                    closePanel={closePanel}
                  />,
                );
              },
            },
          ]}
        />
      }
      alignment="right"
    >
      <IconButton icon="kebab" size="large" />
    </HovercardToggle>
  );
};
