import { animated, useSpring } from "@react-spring/web";

import { ChartColors } from "@/js/constants/cambio";
import { classnames } from "@/js/utils/cambio";

// design doesn't like the way the yellow and orange looks in the progress bars, so leave those out
export const ProgressBarColors = Object.values(ChartColors).filter(
  (color) => ![ChartColors.LEMON, ChartColors.ORANGE].includes(color),
);

/**
 * Simple colored div that translates x% across its parent, denoting a percentage between 1-100.
 */
export default function ProgressBar({
  color = ChartColors.TEAL_DARK,
  progress,
  size = "medium",
  animateOnMount = true,
}: {
  color?: string;
  /** This is a decimal, 0 < progress < 1 */
  progress: number;
  size?: "medium" | "large";
  animateOnMount?: boolean;
}) {
  const springProps = useSpring({
    ...(animateOnMount ? { from: { transform: `translateX(0%)`, backgroundColor: color } } : {}),
    to: { transform: `translateX(${progress * 100}%)`, backgroundColor: color },
    config: { tension: 100 + 20 * progress },
  });

  return (
    <div
      className={classnames("ProgressBar", {
        [size]: size !== "medium",
      })}
    >
      <animated.div style={springProps} />
    </div>
  );
}
